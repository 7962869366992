import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

export default function Example() {
    return (
        <Popover className="bg-white fixed top-0 inset-x-0 z-20 shadow-sm">
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/">
                            <div className="flex items-center">
                                <img src="/logo.svg" alt="logo" style={{ maxWidth: '20%' }} />
                                <div className="ml-2">
                                    <div className="font-bold leading-none">Ethibox</div>
                                    <div className="text-sm leading-none">Hébergeur web éthique</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10">
                        <Link to="/apps" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Applications
                        </Link>
                        <Link to="/pricing" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Tarifs
                        </Link>
                        <Link to="/app/fr/login" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                            Se connecter
                        </Link>
                        <Link to="/app/fr/register" className="button ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-700 hover:bg-gray-800">
                            Essayer gratuitement
                        </Link>
                    </div>
                </div>
            </div>

            <Transition as={Fragment} enter="duration-200 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div className="rounded-lg shadow-lg ring-1 ring-gray-300 ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center text-black">
                                    <img src="/logo.svg" alt="logo" style={{ maxWidth: '20%' }} />
                                    <div className="ml-2">
                                        <div className="font-bold leading-none">Ethibox</div>
                                        <div className="text-sm leading-none">Hébergeur web éthique</div>
                                    </div>
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    <Link to="/apps" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <span className="text-base font-medium text-gray-900">Applications</span>
                                    </Link>
                                    <Link to="/pricing" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <span className="text-base font-medium text-gray-900">Tarifs</span>
                                    </Link>
                                </nav>
                            </div>
                        </div>
                        <div className="py-6 px-5 space-y-6">
                            <div>
                                <Link to="/app/fr/register" className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-gray-700">
                                    Essayer gratuitement
                                </Link>
                                <p className="mt-6 text-center text-base font-medium text-gray-500">
                                    Déjà inscrit ?{' '}
                                    <Link to="/app/fr/login" className="text-gray-600 hover:text-gray-500">
                                        Se connecter
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
