import React from 'react';

import Footer from './footer';
import Header from './header';

export default ({ children, footerColor = 'bg-gray-50' }) => {
    return (
        <>
            <Header />
            <main className="bg-white">{children}</main>
            <Footer color={footerColor} />
        </>
    );
};
