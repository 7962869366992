import React from 'react';
import { Link } from 'gatsby';

import TwitterIcon from '../images/twitter.svg';
import GithubIcon from '../images/github.svg';
import YoutubeIcon from '../images/youtube.svg';
import PeertubeIcon from '../images/peertube.svg';
import MastodonIcon from '../images/mastodon.svg';
import EthiboxIcon from '../images/ethibox.svg';

export default ({ color }) => {
    return (
        <div className={color}>
            <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="md:grid lg:grid-flow-col">
                    <div className="max-w-md">
                        <EthiboxIcon />
                        <p className="mt-8 text-gray-500 text-base leading-6">
                            Gérez vos données de manière éthique
                        </p>
                        <p className="text-gray-500 text-base leading-6">
                            Toutes vos applications web hébergées sans effort dans un endroit sûr.
                        </p>
                        <div className="mt-8 flex">
                            <a href="https://mastodon.ethibox.fr/@ethibox" className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Mastodon</span>
                                <MastodonIcon className="h-6 w-6 fill-current" />
                            </a>
                            <a href="https://twitter.com/ethibox" className="ml-6 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Twitter</span>
                                <TwitterIcon className="h-6 w-6 fill-current" />
                            </a>
                            <a href="https://github.com/ethibox" className="ml-6 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Github</span>
                                <GithubIcon className="h-6 w-6 fill-current" />
                            </a>
                            <a href="https://peertube.ethibox.fr/" className="ml-6 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">PeerTube</span>
                                <PeertubeIcon className="h-6 w-6 fill-current" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCgevg0na3UXhefKGA4bVUkQ" className="ml-6 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">YouTube</span>
                                <YoutubeIcon className="h-6 w-6 fill-current" />
                            </a>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3">
                        <div className="mt-12 lg:mt-0">
                            <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Entreprise
                            </div>
                            <ul className="mt-4">
                                <li className="mt-4">
                                    <Link to="/about" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        À propos
                                    </Link>
                                </li>
                                <li className="mt-4">
                                    <Link to="/transparence" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Transparence
                                    </Link>
                                </li>
                                <li className="mt-4">
                                    <Link to="/legals" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Mentions légales
                                    </Link>
                                </li>
                                <li className="mt-4">
                                    <Link to="/cgu" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Conditions d'utilisations
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-12 lg:mt-0">
                            <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Support
                            </div>
                            <ul className="mt-4">
                                <li>
                                    <Link to="/support" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Support client
                                    </Link>
                                </li>
                                <li className="mt-4">
                                    <a href="https://status.ethibox.fr" target="_blank" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        État du service
                                    </a>
                                </li>
                                <li className="mt-4">
                                    <Link to="/faq" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        FAQ
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-12 lg:mt-0">
                            <div className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                                Alternatives
                            </div>
                            <ul className="mt-4">
                                <li>
                                    <Link to="/nextcloud" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Alternative à Google Drive
                                    </Link>
                                </li>
                                <li className="mt-4">
                                    <Link to="/matomo" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Alternative à Google Analytics
                                    </Link>
                                </li>
                                <li className="mt-4">
                                    <Link to="/zammad" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Alternative à Zendesk
                                    </Link>
                                </li>
                                <li className="mt-4">
                                    <Link to="/suitecrm" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                                        Alternative à Salesforce
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base leading-6 text-gray-400 xl:text-center">
                        <span className="copyleft">©</span> 2018-{new Date().getFullYear()} Ethibox
                    </p>
                </div>
            </div>
        </div>
    );
};
