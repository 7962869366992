import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

export default ({ title, image, description, article, datePublished, dateModified, tags = [], meta = [] }) => {
    const location = useLocation();

    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    lang
                    logo
                    description
                    author
                    twitter
                    siteUrl
                    siteName
                }
            }
        }
    `);

    const defaultTitle = site.siteMetadata?.title;
    const defaultImage = site.siteMetadata?.logo;
    const defaultDescription = site.siteMetadata?.description;

    return (
        <Helmet
            htmlAttributes={{ lang: site.siteMetadata.lang }}
            title={title ? `${title} | ${site.siteMetadata.title}` : defaultTitle}
            meta={[
                {
                    name: 'description',
                    content: description || defaultDescription,
                },
                {
                    property: 'og:url',
                    content: location.href,
                },
                {
                    property: 'og:site_name',
                    content: site.siteMetadata.siteName,
                },
                {
                    property: 'og:title',
                    content: title || defaultTitle,
                },
                {
                    property: 'og:description',
                    content: description || defaultDescription,
                },
                {
                    property: 'og:type',
                    content: article ? 'article' : 'website',
                },
                {
                    property: 'og:image',
                    content: image || defaultImage,
                },
                ...(tags ? tags.map((tag) => ({
                    property: 'article:tag',
                    content: tag,
                })) : []),
                {
                    name: 'twitter:title',
                    content: title || defaultTitle,
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.twitter,
                },
                {
                    name: 'twitter:site',
                    content: site.siteMetadata.twitter,
                },
                {
                    name: 'twitter:description',
                    content: description || defaultDescription,
                },
                ...(image ? [{
                    name: 'twitter:image',
                    content: image,
                }, {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                }] : [{
                    name: 'twitter:card',
                    content: 'summary',
                }, {
                    name: 'twitter:image',
                    content: defaultImage,
                }]),
            ].concat(meta)}
        >
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': article ? 'Article' : 'WebSite',
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': site.siteMetadata?.siteUrl,
                    },
                    url: location.href,
                    headline: title || defaultTitle,
                    author: {
                        '@type': 'Person',
                        name: site.siteMetadata.author,
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: site.siteMetadata.author,
                        url: site.siteMetadata?.siteUrl,
                        logo: {
                            '@type': 'ImageObject',
                            url: site.siteMetadata.logo,
                        },
                    },
                    description: description || defaultDescription,
                    ...(image && { image }),
                    ...(dateModified && { dateModified }),
                    ...(datePublished && { datePublished }),
                })}
            </script>
        </Helmet>
    );
};
